import React from 'react';
import Player from "./components/Player"
function App(props) {
	return (
		<div className="App">
			<Player/>
		</div>
	);
}

export default App;
